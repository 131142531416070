/* eslint-disable no-restricted-syntax */
import {
  ref, watch, computed, getCurrentInstance,
} from "@vue/composition-api";
import store from "@/store";
import Vue from "vue";
import toast from '@/utils/toast';
import {
  isEnableBed, isEnableContractAssetCheck, parseDateToString, getUserConfiguration,
} from "@/auth/utils";
import moment from "moment";
import { useUtils as useI18nUtils } from '@core/libs/i18n';

export default function useContractModal(props, emit, refFormObserver) {
  const toastification = toast();
  const isTingTong = process.env.VUE_APP_PROVIDER === 'tingtong';
  const refModal = ref(null);
  const isLoading = ref(false);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const additionAssets = ref([]);
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
  };
  const isSubmitting = ref(false);
  const resetModal = () => {
    resetItemLocal();
    isSubmitting.value = false;
  };
  const vm = getCurrentInstance().proxy;
  const userConfiguration = getUserConfiguration();
  const autoCalculateQuantityByTenant = !!(userConfiguration && userConfiguration.autoCalculateQuantityByTenant);
  const leaveTenants = ref([]);
  const itemId = computed(() => (props.item && props.item.id ? props.item.id : null));
  const contractTenants = computed(() => itemLocal.value.contractTenants ?? []);
  const isVisible = computed(() => ((itemId.value === 0 || !itemId.value) && vm.$can('create', 'contract')) || (itemId.value > 0 && vm.$can('update', 'contract')));
  const isVisibleTenant = computed(() => ((itemId.value === 0 || !itemId.value) && vm.$can('create', 'contract')) || (itemId.value > 0 && (vm.$can('update', 'contract') || vm.$can('update-tenant-only', 'contract'))));
  const isVisibleFee = computed(() => ((itemId.value === 0 || !itemId.value) && vm.$can('create', 'contract')) || (itemId.value > 0 && (vm.$can('update', 'contract') || vm.$can('update-fee-only', 'contract'))));
  const fetchDetailData = () => {
    if (itemId.value) {
      isLoading.value = true;
      store.dispatch('contract/getContractDetail', itemId.value)
        .then(response => {
          itemLocal.value = response.data;
          isLoading.value = false;
        })
        .catch(error => {
          toastification.showToastError(error);
          isLoading.value = true;
        });
    }
  };
  const onOpen = () => {
    resetModal();
    fetchDetailData();
  };
  const { t } = useI18nUtils();
  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();
    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    isSubmitting.value = true;

    const data = {
      name: itemLocal.value.name,
      contractNumber: itemLocal.value.contractNumber,
      price: itemLocal.value.price,
      deposit: itemLocal.value.deposit,
      note: itemLocal.value.note,
      paymentDay: itemLocal.value.paymentDay,
      promotionMonth: itemLocal.value.promotionMonth,
      promotionPricePerMonth: itemLocal.value.promotionPricePerMonth,
      promotionDeposit: itemLocal.value.promotionDeposit,
      referenceCode: itemLocal.value.referenceCode,
    };

    if (itemLocal.value.apartment && itemLocal.value.apartment.id > 0) {
      data.apartmentId = itemLocal.value.apartment.id;
    }
    if (itemLocal.value.room && itemLocal.value.room.id > 0) {
      data.roomId = itemLocal.value.room.id;
    }
    if (itemLocal.value.bed && itemLocal.value.bed.id > 0) {
      data.bedId = itemLocal.value.bed.id;
    }
    if (itemLocal.value.reservation) {
      data.reservationId = itemLocal.value.reservation.id;
    }
    if (itemLocal.value.contractTemplate) {
      data.contractTemplateId = itemLocal.value.contractTemplate.id;
    }
    if (itemLocal.value.invoiceTemplate) {
      data.invoiceTemplateId = itemLocal.value.invoiceTemplate.id;
    }
    if (
      itemLocal.value.paymentPeriod
    ) {
      data.paymentPeriod = itemLocal.value.paymentPeriod.value;
    }
    data.signDate = parseDateToString(itemLocal.value.signDate);
    data.startDate = parseDateToString(itemLocal.value.startDate);
    data.endDate = parseDateToString(itemLocal.value.endDate);
    data.billingDate = parseDateToString(itemLocal.value.billingDate);

    // list tenant
    data.tenants = itemLocal.value.contractTenants.map(contractTenant => ({ id: contractTenant.tenant.id, isRepresent: contractTenant.isRepresent, joinDate: parseDateToString(contractTenant.joinDate) }));

    // list service
    data.fees = itemLocal.value.contractFees.map(contractFee => {
      const feeItem = {
        id: contractFee.fee.id,
        coefficient: contractFee.coefficient,
        quantity: contractFee.quantity,
        firstIndex: contractFee.firstIndex,
      };
      feeItem.billingDate = parseDateToString(contractFee.billingDate);

      if (contractFee.meter && contractFee.meter.id > 0) {
        feeItem.meterId = contractFee.meter.id;
      }
      return feeItem;
    });

    // attachments
    if (itemLocal.value.attachments) {
      data.attachments = itemLocal.value.attachments.map(object => object.location);
    }

    // handover
    if (itemLocal.value.handover && itemLocal.value.handover.length > 0) {
      data.handover = itemLocal.value.handover.map(object => {
        const temp = {
          assetId: object.asset.id,
          isNormal: object.isNormal,
          note: object.note,
          base64String: object.base64String,
        };
        if (object.id && object.id > 0) {
          temp.id = object.id;
        }
        return temp;
      });
    }

    // other handover
    if (isTingTong) {
      if (itemLocal.value.otherHandover && itemLocal.value.otherHandover.length > 0) {
        data.otherHandover = itemLocal.value.otherHandover.map(object => {
          const temp = {
            name: object.name,
            status: object.status,
            note: object.note,
            penaltyFee: object.penaltyFee,
          };
          if (object.id && object.id > 0) {
            temp.id = object.id;
          }
          return temp;
        });
      }
    }

    //
    if (itemLocal.value.id && itemLocal.value.id > 0 && leaveTenants.value.length > 0) {
      data.leaveTenants = leaveTenants.value;
    }

    isSubmitting.value = true;
    if (itemLocal.value.id && itemLocal.value.id > 0) {
      data.id = itemLocal.value.id;
      store
        .dispatch("contract/updateContract", data)
        .then(response => {
          emit("on-item-updated", response.data);
          isSubmitting.value = false;
        })
        .then(() => {
          refModal.value.toggle("#toggle-btn");
        })
        .then(() => {
          emit("refetch-data");
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    } else {
      store
        .dispatch("contract/createContract", data)
        .then(response => {
          emit("on-item-created", response.data);
          isSubmitting.value = false;
        })
        .then(() => {
          refModal.value.toggle("#toggle-btn");
        })
        .then(() => {
          emit("refetch-data");
        })
        .then(() => {
          toastification.showToastCreateSuccess();
          isSubmitting.value = false;
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
          isSubmitting.value = false;
        });
    }
  };

  const onDeleteAttachment = () => {

  };
  const updateFeeQuantityBaseOnTenants = () => {
    if (!itemLocal.value.contractTenants || !itemLocal.value.contractFees || !autoCalculateQuantityByTenant) {
      return;
    }
    const tempContractFee = itemLocal.value.contractFees;
    for (const eachContractFee of tempContractFee) {
      if (eachContractFee.fee && eachContractFee.fee.unit === 'Người') {
        eachContractFee.quantity = itemLocal.value.contractTenants.length;
      }
    }
    Vue.set(itemLocal.value, "contractFees", [...tempContractFee]);
  };

  const onAddTenant = listTenantSelected => {
    const existIds = itemLocal.value.contractTenants ? itemLocal.value.contractTenants.map(object => (object.tenant ? object.tenant.id : 0)) : [];
    const listTenant = listTenantSelected.filter(object => !existIds.includes(object.id)).map(({ id, phone, name }) => ({
      tenant: {
        id,
        name,
        phone,
      },
      isRepresent: !!itemLocal.value.bed,
      joinDate: itemLocal.value.startDate,
    }));
    const arrMixUpTenants = [...itemLocal.value.contractTenants, ...listTenant];
    const checkHasRepresent = arrMixUpTenants.filter(tenant => !!tenant.isRepresent);
    if (!checkHasRepresent.length && arrMixUpTenants.length) arrMixUpTenants[0].isRepresent = true;
    Vue.set(itemLocal.value, "contractTenants", arrMixUpTenants);
  };

  const onAddService = listServiceSelected => {
    const existIds = itemLocal.value.contractFees ? itemLocal.value.contractFees.map(object => (object.fee ? object.fee.id : 0)) : [];
    const listService = listServiceSelected.filter(object => !existIds.includes(object.id)).map(obj => {
      const quantity = obj.unit === 'Người' ? itemLocal.value.contractTenants.length ?? 0 : 1;
      return {
        fee: obj,
        quantity,
        coefficient: 1,
        firstIndex: 0,
        billingDate: itemLocal.value.billingDate,
        lastBillingDate: itemLocal.value.billingDate,
        meter: null,
      };
    });
    const arrMixUpServices = [...itemLocal.value.contractFees, ...listService];
    itemLocal.value.contractFees = arrMixUpServices;
  };

  const onTenantLeave = val => {
    console.log(val);
    leaveTenants.value = [...leaveTenants.value, val];
  };

  const billingDate = computed(() => itemLocal.value.billingDate);
  const apartment = computed(() => itemLocal.value.apartment);
  const room = computed(() => itemLocal.value.room);
  const bed = computed(() => itemLocal.value.bed);

  watch(billingDate, val => {
    if (itemLocal.value && itemLocal.value.fees) {
      itemLocal.value.fees.forEach((_, index) => {
        itemLocal.value.fees[index].billingDate = val;
      });
    }
  });

  const handleReservation = (aRoom, aBed) => {
    if (aBed && aBed.id && aBed.id > 0) {
      const params = {
        filter: {
          roomId: aRoom.id,
          bedId: aBed.id,
        },
      };
      store
        .dispatch('reservation/getActiveReservation', params)
        .then(response => {
          if (response.data) {
            itemLocal.value.deposit = aBed.deposit;
            itemLocal.value.price = response.data.priceForRent;
            itemLocal.value.reservation = response.data;
          } else {
            itemLocal.value.price = aBed.price;
            itemLocal.value.deposit = aBed.deposit;
            itemLocal.value.reservation = null;
          }
        })
        .catch(error => {
          toastification.showToastError(error);
          itemLocal.value.price = aBed.price;
          itemLocal.value.deposit = aBed.deposit;
          itemLocal.value.reservation = null;
        });
    } else if (aRoom && aRoom.id && aRoom.id > 0) {
      const params = {
        filter: { roomId: aRoom.id },
      };
      store
        .dispatch('reservation/getActiveReservation', params)
        .then(response => {
          if (response.data) {
            itemLocal.value.reservation = response.data;
            itemLocal.value.deposit = response.data.totalDeposit > 0 ? response.data.totalDeposit : aRoom.deposit;
            itemLocal.value.price = response.data.priceForRent;

            // start date
            if (moment(response.data.startDate).isValid()) {
              itemLocal.value.startDate = moment(response.data.startDate).format('DD-MM-YYYY');
            }
            // end date
            if (moment(response.data.endDate).isValid()) {
              itemLocal.value.endDate = moment(response.data.endDate).format('DD-MM-YYYY');
            }
            // billing date
            if (moment(response.data.billingDate).isValid()) {
              itemLocal.value.billingDate = moment(response.data.billingDate).format('DD-MM-YYYY');
            }
            // payment period
            onAddTenant([response.data.tenant]);
            onAddService(response.data.fees);

            Vue.set(itemLocal.value, "paymentPeriod", response.data.paymentPeriod);
          } else {
            itemLocal.value.price = aRoom.price;
            itemLocal.value.deposit = aRoom.deposit;
            itemLocal.value.reservation = null;
          }
        })
        .catch(error => {
          toastification.showToastError(error);
          itemLocal.value.price = aRoom.price;
          itemLocal.value.deposit = aRoom.deposit;
          itemLocal.value.reservation = null;
        });
    } else {
      itemLocal.value.price = 0;
      itemLocal.value.deposit = 0;
      itemLocal.value.reservation = null;
    }
  };

  const onStartDateChange = val => {
    if (val && (!itemLocal.value.id || itemLocal.value.id === 0)) {
      itemLocal.value.endDate = moment(val, 'DD-MM-YYYY', true).add(6, 'months').subtract(1, 'd').format('DD-MM-YYYY');
    }
  };

  const onSelectAssets = selectedAssets => {
    additionAssets.value = selectedAssets;
  };

  watch(apartment, val => {
    if ((!itemLocal.value.id || itemLocal.value.id === 0) && val) {
      itemLocal.value.paymentDay = val.paymentDay;
    }
  });

  watch(room, val => {
    if (!itemLocal.value.id || itemLocal.value.id === 0) {
      handleReservation(val, null);
    }
  });

  watch(bed, val => {
    if (!itemLocal.value.id || itemLocal.value.id === 0) {
      handleReservation(room, val);
    }
  });

  watch(contractTenants, () => {
    updateFeeQuantityBaseOnTenants();
  });

  const resolveColWidthIfDisableBed = isBed => {
    if (isEnableBed()) {
      return 4;
    }
    if (isBed) {
      return 0;
    }
    return 6;
  };

  return {
    isLoading,
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    isTingTong,
    additionAssets,
    resetItemLocal,
    resetModal,
    onSubmit,
    onAddTenant,
    onAddService,
    onDeleteAttachment,
    onStartDateChange,
    resolveColWidthIfDisableBed,
    isEnableContractAssetCheck,
    onSelectAssets,
    t,
    onOpen,
    isVisible,
    isVisibleTenant,
    isVisibleFee,
    onTenantLeave,
  };
}
